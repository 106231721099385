h1,
h2,
h3,
h4,
h5,
h6 {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    margin-bottom: 0.5em;
    margin-top: 0;
}
.flexBox {
    display: flex;
}
.g-30 {
    gap: 30px;
}
.flex-1 {
    flex: 1;
}
h2 {
    font-size: 16px;
    font-weight: 500;
    box-sizing: border-box;
}
*:focus,
*:focus-within,
*:focus-visible {
    outline: none !important;
    box-shadow: none !important;
}
