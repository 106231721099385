.card-tr-user {
    height: 52px !important;
    box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.5);
    border-radius: 6px;

   
}
tr{
  text-align: left;
}
th{
  padding: 9px;
}