$black: #000000;
$tawny: #CC5803;
$silver: #B4B3B3;
$white: #FFFFFF;
$misty: #FDECDF;

.price_variation {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

table {
  border-collapse: unset;
  border-spacing: 5px 11px !important;
}

.card-tr {
  height: 91px !important;
  box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

.btn-form {
  border-radius: 30px;
  position: relative;
  border: 1px solid $tawny;
  outline: 1px solid;
  outline-color: $tawny;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  margin: 0 0 0 1.5rem;
  
  .btn-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    padding-left: 0.3rem;
  }

  &:not(.btn-search):hover {
    border: 1px solid $tawny;
    box-shadow: 0px 10px 25px rgba(204, 88, 3, 0.34);
    outline-color: rgba(49, 138, 172, 0);
    outline-offset: 5px;
  }
}

.title {
  margin: 3rem;
  display: flex;
  justify-content: space-between;
}

.item-form {
  margin: 1rem;
  
  .form-group {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 15px;
  
    .label-form {
      transition: all 0.3s ease;
      display: inline-block;
      margin-bottom: 5px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      color: #1F1300;
    }
    
    .input-form {
      transition: all 0.3s ease;
      border: 1px solid #eeeeee;
      display: block;
      width: 95%;
      height: 35px;
      background: #FFFFFF;
      border: 0.5px solid #B2B2B2;
      border-radius: 5px;
      margin: 0.5rem 0 0.5rem 0;

      padding-left: 10px;
      caret-color: $tawny;
  
      &:focus {
        outline: none;
        border: 0.5px solid #CC5803;
        
        +.label-form {
          color: #CC5803;
        }
      }
    }
  
    .span-form {
      display: block;
      width: 95% !important;
      height: 13px;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #8E8E8E;
    }
  }
  
  .ant-upload.ant-upload-select-picture-card {
    margin-top: 1rem;
    width: 155px;
    height: 139px;
    background: $misty !important;
    border: 1px dashed #FFCCA7 !important;
    border-radius: 20px;
    padding:2rem;
  }
  
  span.ant-upload {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #CC5803;
  }
}